import React from 'react';
import {Route, Switch} from 'react-router';

import About from './components/about';
import Contact from './components/contact';
import Home from './components/home';
import NotFound from './components/not-found';
import Services from './components/services';
import Spaces from './components/spaces';

export default (
    <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/residencial' render={(props) => <Spaces {...props} slug='residencial' />} />,
        <Route path='/corporativo' render={(props) => <Spaces {...props} slug='corporativo' />} />,
        <Route exact path='/acerca' component={About} />
        <Route exact path='/servicios' component={Services} />
        <Route exact path='/contacto' component={Contact} />
        <Route path='*' component={NotFound} />
    </Switch>
);
