import './styles/index.scss';

import React from 'react';
import {hydrate, render} from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import {createBrowserHistory} from "history";
import Main from './components/main';
import ScrollToTop from './components/scroll-to-top';

const rootElement = document.getElementById('root');
const component = (
    <Router>
        <ScrollToTop>
            <Main history={createBrowserHistory()} />
        </ScrollToTop>
    </Router>
);

if (rootElement.hasChildNodes()) {
    hydrate(component, rootElement);
} else {
    render(component, rootElement);
}
