import React, {useState, useEffect} from 'react';
import {matchPath} from 'react-router';
import PropTypes from 'prop-types';
import {NavLink, Link} from 'react-router-dom';
import Helmet from '@patio23/shared-components/lib/helmet';
import classNames from 'classnames';

import routes from '../routes';

import RetinaImage from 'react-retina-image';

export default function Main (props) {
    const [blueHeader, setBlueHeader] = useState(false);
    const [showMobileNav, setShowMobileNav] = useState(false);

    const handleWindowScroll = () => {
        setBlueHeader(window.scrollY > 0);
    }

    useEffect (() => {
        handleWindowScroll();
        window.addEventListener('scroll', handleWindowScroll);

        return () => {
            window.removeEventListener('scroll', handleWindowScroll);
        }
    }, []);

    function toggleMobileNav () {
        if (window.innerWidth > 850) {
            return;
        }

        setShowMobileNav(!showMobileNav);
    }

    function renderEmptySpan () {
        return (<span> </span>); // eslint-disable-line react/self-closing-comp
    }

    function getCurrentRoute () {
        for (let i = 0; i < routes.props.children.length; i++) {
            const route = routes.props.children[i].props;
            if (matchPath(props.history.location.pathname, route)) {
                return route;
            }
        }

        return {};
    }


    function renderHeader (route) {
        if (route.hideHeaderFooter) {
            return;
        }

        const useBlueHeader = blueHeader || showMobileNav;
        return (
            <header className={classNames({'blue-header': useBlueHeader})}>
                <nav>
                    <NavLink to='/' className='logo-wrapper' onClick={toggleMobileNav}>
                        <RetinaImage src={[require('../assets/inteligable-logo.png'), require('../assets/inteligable-logo@2x.png')]} height='28' />
                    </NavLink>
                    <div className={classNames('menu', 'tinted-color', showMobileNav ? 'icon-close' : 'icon-menu')} onClick={toggleMobileNav} />
                    <ul className={classNames({hidden: !showMobileNav})}>
                        <li><NavLink to='/residencial' activeClassName='active' onClick={toggleMobileNav}>Residencial</NavLink></li>
                        <li><NavLink to='/corporativo' activeClassName='active' onClick={toggleMobileNav}>Corporativo</NavLink></li>
                        <li><NavLink to='/servicios' activeClassName='active' onClick={toggleMobileNav}>Servicios</NavLink></li>
                        <li><NavLink to='/acerca' activeClassName='active' onClick={toggleMobileNav}>Acerca</NavLink></li>
                        <li><NavLink to='/contacto' activeClassName='active' onClick={toggleMobileNav}>Contacto</NavLink></li>
                        <li className='portal-link'><a href='https://portal.inteligable.com' target='_blank' rel='noopener noreferrer'>Portal</a></li>
                    </ul>
                </nav>
            </header>
        );
    }

    function renderFooter (route) {
        if (route.hideHeaderFooter) {
            return;
        }

        return (
            <footer>
                <div className='centered'>
                    <div className='footer-section logo'>
                        <div className='section-info'>
                            <Link to='/'><RetinaImage src={[require('../assets/inteligable-logo.png'), require('../assets/inteligable-logo@2x.png')]} height='28' /></Link>
                        </div>
                        <div className='section-info caption'>
                            <span>espacios inteligentes pero amigables.</span>
                        </div>
                    </div>
                    {renderEmptySpan()}

                    <div className='footer-section address-section'>
                        <div className='section-info wave'>
                            <span className='icon-decor-wave' />
                        </div>
                        <div className='section-info'>
                            <div>
                                <span className='icon-location' />
                                <div className='address'>
                                    <span>Carretera Nacional 500</span>
                                    <span>Col. Valle Alto</span>
                                    <span>Monterrey, N.L.</span>
                                    <span>México 64989</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {renderEmptySpan()}

                    <div className='footer-section contact-info'>
                        <h6>¡Contáctanos!</h6>
                        <div className='section-info'>
                            <div>
                                <span className='icon-whatsapp icon' />
                                <a href='https://wa.me/528119314049' target='_blank' rel='noopener noreferrer'>+52 (81) 1931-4049</a>
                            </div>
                            <div>
                                <span className='icon-mail icon' />
                                <a href='mailto:hola@inteligable.com'>hola@inteligable.com</a>
                            </div>
                        </div>
                    </div>
                    {renderEmptySpan()}

                    <div className='footer-section memberships'>
                        <h6>Miembro</h6>
                        <div className='section-info'>
                            <a target='_blank' rel='noopener noreferrer' href='https://www.avixa.org'><img alt='Avixa' src={[require('../assets/about/avixa.png')]} height='20' /></a>
                            <a target='_blank' rel='noopener noreferrer' href='https://www.cedia.org'><img alt='Cedia' src={[require('../assets/about/cedia.png')]} height='20' /></a>
                            <a target='_blank' rel='noopener noreferrer' href='https://www.nfpa.org'><img alt='NFPA' src={[require('../assets/about/nfpa.png')]} height='20' /></a>
                        </div>
                    </div>
                </div>
                <div className='centered reserved-developed'>
                    <div className='reserved-rights'>
                        <span>{'\u00A9'} {(new Date()).getFullYear()} Espacios Inteligable. Todos los derechos reservados.</span>
                    </div>
                    <div className='developed-by'>
                        <span>Desarrollado con </span><span className='icon-heart-2' /><span> por </span><a target='_blank' rel='noopener noreferrer' href='https://www.patio23.com'>Patio 23</a><span> en Monterrey, Mx.</span>
                    </div>
                </div>
            </footer>
        );
    }

    const route = getCurrentRoute();
    const title = 'Inteligable - Espacios Inteligentes pero Amigables';
    const description = 'Espacios inteligentes pero amigables. Smart Homes, Audio, Video, Wi-Fi, Redes, Home Theaters, Iluminación, Alarmas, CCTV, Temperatura. Monterrey, MX';

    return (
        <div>
            <Helmet defaultTitle={title} titleTemplate='Inteligable - %s'>
                <meta name='description' content={description} />

                <meta property='og:image' content={require('../assets/og-inteligable.png')} />
                <meta property='og:type' content='website' />
                <meta property='og:locale' content='es_MX' />

                <meta name='twitter:card' content='summary_large_image' />
            </Helmet>
            {renderHeader(route)}
            {routes}
            {renderFooter(route)}
        </div>
    );
}

Main.propTypes = {
    history: PropTypes.object
};
