import '../styles/about.scss';

import React from 'react';

export default function About (props) {

    return (
        <div className='about'>
            <div className='hero section-hero'>
                <div className='image' style={{backgroundImage: `url(${require('../assets/backgrounds/acerca.jpg')})`}} />
                <h1 className='hero-title'>Acerca</h1>
            </div>
            <div className='centered main-content'>
                <h1 className='with-line'>Acerca</h1>
                <div className='about-description-wrapper'>
                    <p>Inteligable surgió del amor que tenemos por la tecnología y la construcción. Cansados de proveedores que prometían el cielo y las estrellas y terminaban instalando equipo de más y sobre todo equipo difícil de usar, nació Inteligable.</p>
                    <p>Nuestra prioridad es ofrecer las mejores soluciones tecnológicas con el mejor servicio al cliente. Además, nos gusta cuidar cada detalle y hacer las cosas bien, siguiendo y siempre cumpliendo estándares y normas nacionales e internacionales.</p>
                </div>
                <div className='history-description-wrapper'>
                    <p>En Inteligable, lo más importante son nuestros clientes. Sabemos y entendemos que la tecnología puede ser confusa, por lo que siempre nos aseguramos de instalar solo aquello que realmente es necesario, amigable y fácil de usar.</p>
                    <p>Nuestra pasión es diseñar espacios inteligentes a la medida, siempre cuidando las necesidades y el presupuesto de nuestros clientes.</p>
                </div>
                <div className='memberships'>
                    <h1>Asociaciones</h1>
                    <p>Para poder ofrecer las mejores soluciones y servicio al cliente se requiere primeramente de la formación y educación interna del equipo. Es por esto que somos miembros activos de diferentes asociaciones a nivel mundial, a través de las cuales nuestros técnicos reciben la educación y certificación necesaria para lograrlo. </p>
                    <a target='_blank' rel='noopener noreferrer' href='https://www.avixa.org' className='membership'><img alt='Avixa' src={[require('../assets/about/avixa.png')]} height='55' /></a>
                    <a target='_blank' rel='noopener noreferrer' href='https://www.cedia.org' className='membership'><img alt='Cedia' src={[require('../assets/about/cedia.png')]} height='55' /></a>
                    <a target='_blank' rel='noopener noreferrer' href='https://www.nfpa.org' className='membership'><img alt='NFPA' src={[require('../assets/about/nfpa.png')]} height='55' /></a>
                </div>
            </div>
        </div>
    );

}
