import '../styles/slideshow.scss';

import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function Slideshow (props) {
    const [state, setState] = useState({
        selectedIndex: 0,
        backImage: props.slides[0].image,
        frontImage: props.slides[1].image,
        backTitle: props.slides[0].title,
        frontTitle: props.slides[1].title,
        frontClassName: 'hidden'
    });

    let timeoutId = () => {};

    useEffect (() => {
        mysetTimeout();

        return () => {
            clearTimeout(timeoutId);
        }
    });

    function mysetTimeout () {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(handleInterval, 8000);
    }

    function handleInterval () {
        showImage((state.selectedIndex + 1) % props.slides.length);
    }

    function handleSelectIndicator (event) {
        showImage(parseInt(event.currentTarget.dataset.index, 10));
    }

    function renderIndicators () {
        return props.slides.map(function (slide, index) {
            return (
                <div
                    key={'indicator-' + index}
                    data-index={index}
                    className={classNames('indicator', {selected: index === state.selectedIndex})}
                    onClick={handleSelectIndicator}>
                    <span />
                </div>
            );
        });
    }

    function showImage (index) {
        if (state.frontClassName === 'fade-in') {
            setState({
                ...state,
                selectedIndex: index,
                backImage: props.slides[index].image,
                backTitle: props.slides[index].title,
                frontClassName: 'fade-out'
            });
        } else {
            setState({
                ...state,
                selectedIndex: index,
                frontImage: props.slides[index].image,
                frontTitle: props.slides[index].title,
                frontClassName: 'fade-in'
            });
        }
    }

    return (
        <div className='slideshow'>
            <div style={{backgroundImage: `url(${state.backImage})`}} className='image' />
            <div style={{backgroundImage: `url(${state.frontImage})`}} className={classNames('image', state.frontClassName)} />
            <div className='overlay'>
                <div className='centered'>
                    <div className={classNames('text')}>
                        {state.backTitle}
                    </div>
                    <div className={classNames('text', state.frontClassName)}>
                        {state.frontTitle}
                    </div>
                    <div className='indicators'>
                        {renderIndicators()}
                    </div>
                </div>
            </div>
        </div>
    );
}

Slideshow.propTypes = {
    slides: PropTypes.array.isRequired
};
