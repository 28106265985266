import '../styles/services.scss';

import React from 'react';
import classNames from 'classnames';

const services = require('../data/services.json');

export default function Services (props) {
    function renderServices () {
        return services.map(function (service, index) {
            return (
                <div key={'service-' + service.title} className={classNames('service-wrapper', {'moved-up': index === 0 || index === 2 || index === 3 || index === 5 || index === 6 || index === 8})} id={service.id}>
                    <div className='service'>
                        <span className={'icon icon-' + service.icon} />
                        <span className='title'>{service.title}</span>
                        <p>{service.description}</p>
                    </div>
                </div>
            );
        });
    }

    return (
        <div className='services'>
            <div className='hero section-hero'>
                <div className='image' style={{backgroundImage: `url(${require('../assets/backgrounds/servicios.jpg')})`}} />
                <h1 className='hero-title'>Servicios</h1>
            </div>
            <div className='centered main-content'>
                <div className='section-title'>
                    <h1 className='with-line'>Servicios</h1>
                    <p className='intro'>En Inteligable nos encargamos desde la evaluación de tus necesidades hasta el servicio post-instalación. Nosotros cuidamos cada detalle desde el cableado, la instalación y la programación. Además, aunque todo lo que instalamos es amigable y fácil de usar,  nos encargamos de darte toda la capacitación necesaria.</p>
                </div>
                <div className='services-wrapper'>
                    {renderServices()}
                </div>
            </div>
        </div>
    );
}
