import '../styles/contact.scss';

import React, {useReducer, useState, useEffect} from 'react';
import classNames from 'classnames';
import 'whatwg-fetch';
import Promise from 'promise-polyfill';
import {
    submitContactFormStart, submitContactFormSuccess,
    submitContactFormError, resetReducer
} from '../actions/contact';
import {initialReducerState, reducer} from '../reducers/contact';

import Helmet from '@patio23/shared-components/lib/helmet';

const initialState = {
    name: '',
    email: '',
    phone: '',
    message: '',
    dialog: null,
    disabled: false
};

export default function Contact (props) {
    const [reducerState, dispatch] = useReducer(reducer, initialReducerState);
    const [state, setState] = useState(initialState);

    useEffect(() => {
        if (reducerState.submitting) {
            setState(prevState => {
                return {
                    ...prevState,
                    disabled: true
                };
            });
        }

        if (reducerState.submitted) {
            setState(prevState => {
                return {
                    ...initialState,
                    dialog: {text: '<p>¡Gracias por contactarnos!</p><p>Tu mensaje ha sido enviado.</p>'}
                };
            });

            dispatch(resetReducer());
        }

        if (reducerState.submitError) {
            setState(prevState => {
                return {
                    ...prevState,
                    disabled: false,
                    dialog: {text: '<p>Lo sentimos.</p><p>Por favor, intenta de nuevo.</p>'}
                };
            });
        }
    }, [reducerState]);

    function handleChange (event) {
        setState({
            ...state,
            [event.target.name]: event.target.value,
            [event.target.name + 'Error']: false
        });
    }

    function hideDialog () {
        setState({
            ...state,
            dialog: null
        });
    }

    const isValidEmail = (email) => {
        return (/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i).test(email);
    }

    function handleSubmit (event) {
        event.preventDefault();

        let invalidFields = false;
        const newState = {...state};

        if (!state.name.trim()) {
            invalidFields = true;
            newState.nameError = true;
        }

        if (!state.email.trim() || !isValidEmail(state.email)) {
            invalidFields = true;
            newState.emailError = true;
        }

        if (!state.phone.trim()) {
            invalidFields = true;
            newState.phoneError = true;
        }

        if (!state.message.trim()) {
            invalidFields = true;
            newState.messageError = true;
        }

        if (invalidFields) {
            return setState(newState);
        }

        submitForm();
    }

    function submitForm () {
        dispatch(submitContactFormStart());

        if (!window.Promise) {
            window.Promise = Promise;
        }

        fetch('https://api.patio23.com/contact-mailer/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: state.email,
                message: `Mensaje:\n${state.message}\n\nTeléfono: ${state.phone}`,
                subject: '[Inteligable] Mensaje de: ' + state.name
            })
        }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
                dispatch(submitContactFormSuccess());
                return response.status < 204 ? response.json() : {};
            } else {
                const error = new Error(response.statusText);
                error.response = response;
                dispatch(submitContactFormError(error));
            }
        });
    }

    function renderDialog () {
        if (!state.dialog) {
            return;
        }

        return (
            <div className='dialog-backdrop'>
                <div className='dialog'>
                    <div dangerouslySetInnerHTML={{__html: state.dialog.text}} className='text' />
                    <button type='button' onClick={hideDialog}>cerrar</button>
                </div>
            </div>
        );
    }

    return (
        <div className='contact'>
            <Helmet>
                <html className={state.dialog ? 'no-scroll' : null} />
            </Helmet>
            <div className='hero section-hero'>
                <div className='image' style={{backgroundImage: `url(${require('../assets/backgrounds/contacto.jpg')})`}} />
                <h1 className='hero-title'>Contacto</h1>
            </div>
            <div className='centered main-content'>
                <div className='section-title'>
                    <h1 className='with-line'>Contacto</h1>
                    <p>¿Te interesa conocer más sobre los servicios que ofrecemos? o, ¿te gustaría recibir una propuesta para algún espacio? Envíanos un correo o un WhatsApp y con gusto te atenderemos.</p>
                </div>
                <div className='contact-info-wrapper'>
                    <div className='contact-info'>
                        <span>Puedes contactarnos en:</span>
                        <a href='https://wa.me/528184219050' target='_blank' rel='noopener noreferrer' className='bold'>(81) 1931-4049<span className='icon-whatsapp' /></a>
                        <a className='bold' href='mailto:hola@inteligable.com'>hola@inteligable.com</a>
                    </div>
                </div>
                <h3>Envíanos tus datos</h3>
                <form onSubmit={handleSubmit}>
                    <div className='left-column'>
                        <input
                            type='text'
                            placeholder='Nombre'
                            value={state.name}
                            name='name'
                            className={classNames({invalid: state.nameError})}
                            disabled={state.disabled}
                            onChange={handleChange} />
                        <input
                            type='text'
                            placeholder='Correo electrónico'
                            value={state.email}
                            name='email'
                            className={classNames({invalid: state.emailError})}
                            disabled={state.disabled}
                            onChange={handleChange} />
                        <input
                            type='text'
                            placeholder='Teléfono'
                            value={state.phone}
                            name='phone'
                            className={classNames({invalid: state.phoneError})}
                            disabled={state.disabled}
                            onChange={handleChange} />
                    </div>
                    <div className='right-column'>
                        <textarea
                            placeholder='Mensaje'
                            value={state.message}
                            name='message'
                            className={classNames({invalid: state.messageError})}
                            disabled={state.disabled}
                            onChange={handleChange} />
                        <button type='submit' disabled={state.disabled}>{state.disabled ? 'enviando...' : 'enviar'}</button>
                    </div>
                </form>
            </div>
            {renderDialog()}
        </div>
    );
}
