import '../styles/services-home.scss';

import React from 'react';
import {Link} from 'react-router-dom';

const services = require('../data/services.json');

export default function ServicesHome (props) {
    function renderServices () {
        return services.map(function (service, index) {
            return (
                <div key={'service-' + service.title} className='service-wrapper'>
                    <a href={'/servicios#' + service.id} className='service'>
                        <span className={'icon icon-' + service.icon} />
                        <span className='title'>{service.title}</span>
                    </a>
                </div>
            );
        });
    }

    return (
        <div className='services-home'>
            <div className='centered'>
                <div className='section-title'>
                    <h1>Servicios</h1>
                    <p className='intro'>En Inteligable nos encargamos de la evaluación de tus necesidades y el servicio post-instalación.</p>
                </div>
                <div className='services-wrapper'>
                    {renderServices()}
                </div>
                <Link to='/servicios' className='more'>conoce más<span className='icon-plus' /></Link>
            </div>
        </div>
    );
}
