import '../styles/spaces.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import RetinaImage from 'react-retina-image';

const corporate = require('../data/corporate.json');
const residential = require('../data/residential.json');
const services = require('../data/services.json');

export default function Spaces (props) {
    function getSectionIntro () {
        if (props.slug === 'residencial') {
            return 'Desde una red Wi-Fi que realmente funcione, hasta una sala de cine dedicada (Home Theater), nuestros especialistas están para ayudarte. Podemos apoyarte con los planos de un proyecto o con una residencia ya existente.';
        }

        return 'Hoy en día la tecnología juega una papel importantísimo en el mundo laboral. En Inteligable te podemos apoyar con todo desde la red de datos hasta el audio ambiental.';
    }

    function renderHero () {
        if (props.slug === 'residencial') {
            return (
                <div className='hero section-hero'>
                    <div className='image' style={{backgroundImage: `url(${require('../assets/backgrounds/residencial.jpg')})`}} />
                    <h1 className='hero-title'>Residencial</h1>
                </div>
            );
        }

        return (
            <div className='hero section-hero'>
                <div className='image' style={{backgroundImage: `url(${require('../assets/backgrounds/oficina.jpg')})`}} />
                <h1 className='hero-title'>Corporativo</h1>
            </div>
        );
    }

    function renderSpecialties () {
        return (props.slug === 'residencial' ? residential : corporate).map(function (specialty) {
            return (
                <div key={'specialty-' + specialty.title} className='specialty-wrapper'>
                    <div className='specialty'>
                        <h6>{specialty.title}</h6>
                        {renderSpecialtyServices(specialty.services)}
                    </div>
                </div>
            );
        });
    }

    function renderSpecialtyServices (specialtyServices) {
        return specialtyServices.map(function (service) {
            return (
                <div key={'specialty-service-' + service.icon} className='specialty-service'>
                    <RetinaImage src={[require('../assets/specialties/' + service.icon + '-color.png'), require('../assets/specialties/' + service.icon + '-color@2x.png')]} height='92' />
                    <p>{service.description}</p>
                    <div className='service-brands'>
                        {renderBrands(service.brands)}
                    </div>
                </div>
            );
        });
    }

    function renderBrands (brands) {
        return brands.map(function (brand) {
            return (<img key={'brand-' + brand} src={require('../assets/brands/' + brand + '@2x.png')} alt={brand} />);
        });
    }

    function renderServices () {
        const items = [];

        services.forEach(function (service) {
            if (service.id !== 'diseno' && service.id !== 'ingenieria' && service.id !== 'cableado' && service.id !== 'instalacion' && service.id !== 'programacion') {
                return;
            }

            items.push(
                <div key={'service-' + service.id} className='service-wrapper'>
                    <div className='service'>
                        <span className={'icon icon-' + service.icon} />
                        <span className='title'>{service.title}</span>
                    </div>
                </div>
            );
        });

        return items;
    }

    function renderCompatibleWith () {
        if (props.slug === 'corporativo') {
            return;
        }

        return (
            <div className='compatible-with'>
                <hr />
                <h5>Compatible con...</h5>
                <div className='assistant-wrapper'>
                    <div className='assistant-image'>
                        <div className='image' style={{backgroundImage: `url(${require('../assets/residential/residencial02.jpg')})`}} />
                    </div>
                    <div className='assistant-logo'>
                        <RetinaImage src={[require('../assets/brands/amazon-alexa.jpg'), require('../assets/brands/amazon-alexa@2x.jpg')]} height='50' />
                    </div>
                </div>
                <div className='assistant-wrapper'>
                    <div className='assistant-image'>
                        <div className='image' style={{backgroundImage: `url(${require('../assets/residential/residencial03.jpg')})`}} />
                    </div>
                    <div className='assistant-logo'>
                        <RetinaImage src={[require('../assets/brands/apple-homekit.jpg'), require('../assets/brands/apple-homekit@2x.jpg')]} height='50' />
                    </div>
                </div>
                <div className='assistant-wrapper'>
                    <div className='assistant-image'>
                        <div className='image' style={{backgroundImage: `url(${require('../assets/residential/residencial04.jpg')})`}} />
                    </div>
                    <div className='assistant-logo'>
                        <RetinaImage src={[require('../assets/brands/google-assistant.jpg'), require('../assets/brands/google-assistant@2x.jpg')]} height='50' />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='spaces'>
            {renderHero()}
            <div className='centered main-content'>
                <p className='section-intro'>{getSectionIntro()}</p>
                <div className='section-title'>
                    <h1 className='with-line'>Especialidades</h1>
                </div>
                {renderSpecialties()}
                <div className='services-section'>
                    <p className='intro'>Además de todo lo anterior mencionado, en Inteligable nos encargamos del diseño, cableado, instalación, programación y mantenimiento.</p>
                    <div className='services-wrapper'>
                        {renderServices()}
                    </div>
                    <Link to='/servicios' className='more'>conoce más<span className='icon-plus' /></Link>
                </div>
                {renderCompatibleWith()}
            </div>
        </div>
    );
}

Spaces.propTypes = {
    location: PropTypes.object.isRequired,
    slug: PropTypes.string.isRequired
};
