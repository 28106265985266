import React from 'react';
import {Link} from 'react-router-dom';

import Brands from './brands';
import ServicesHome from './services-home';
import Slideshow from './slideshow';
import Specialties from './specialties';

export default function Home (props) {
    return (
        <div className='inteligable-wrapper'>
            <Slideshow
                slides={[
                    {
                        title: <Link key='hero-title-1' to='/residencial'><h1 className='coloured'>Residencias</h1><h1> inteligentes pero amigables.</h1><span className='icon-go-right' /></Link>,
                        image: require('../assets/hero1.jpg')
                    },
                    {
                        title: <Link key='hero-title-2' to='/corporativo'><h1 className='coloured'>Corporativos</h1><h1> inteligentes pero amigables.</h1><span className='icon-go-right' /></Link>,
                        image: require('../assets/hero2.jpg')
                    }
                ]}
            />
            <div className='definition-wrapper'>
                <div className='centered'>
                    <div className='left-column'>
                        <span className='word'>inte’li-gable</span>
                        <span className='origin'>De </span><span className='origin italic'>inteligente</span><span className='origin'> + amigable</span>
                    </div>
                    <div className='right-column'>
                        <div className='definition'>
                            <span className='bold'>1.</span><span className='definition-class'> s. </span><span>Espacios diseñados a la medida en base a las necesidades y preferencias de sus usuarios.</span>
                        </div>
                        <div className='definition'>
                            <span className='bold'>2.</span><span className='definition-class'> adj. </span><span>Solo lo que necesitas.</span>
                        </div>
                    </div>
                </div>
                <div className='colors-bar'>
                    <div className='first' />
                    <div className='second' />
                    <div className='third' />
                    <div className='fourth' />
                    <div className='fifth' />
                </div>
            </div>
            <Specialties />
            <ServicesHome />
            <Brands />
        </div>
    );
}
