import {
    SUBMIT_CONTACT_FORM_START,
    SUBMIT_CONTACT_FORM_SUCCESS,
    SUBMIT_CONTACT_FORM_ERROR,
    RESET
} from '../actions/contact';

export const initialReducerState = {
    submitting: false,
    submitted: false,
    submitError: null
};

export const reducer = (state, action) => {
    switch (action.type) {
        case SUBMIT_CONTACT_FORM_START:
            return {
                ...state,
                submitting: true,
                submitted: false,
                submitError: null
            };
        case SUBMIT_CONTACT_FORM_SUCCESS:
            return {
                ...state,
                submitting: false,
                submitted: true,
                submitError: null
            };
        case SUBMIT_CONTACT_FORM_ERROR:
            return {
                ...state,
                submitting: false,
                submitted: false,
                submitError: action.error
            };
        case RESET:
            return {
                ...initialReducerState
            };
        default:
            return state;
    }
}
