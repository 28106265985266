import '../styles/specialties.scss';

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import RetinaImage from 'react-retina-image';

const specialties = require('../data/specialties.json');

export default function Specialties (props) {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const selectedSpecialty = specialties[selectedIndex];

    function handleClick (index) {
        setSelectedIndex(index);
    }

    function renderSpecialties () {
        return specialties.map(function (specialty, index) {
            return (
                <Specialty
                    key={'specialty-' + specialty.title}
                    index={index}
                    specialty={specialty}
                    selected={selectedIndex === index}
                    onClick={handleClick} />
            );
        });
    }

    return (
        <div className={classNames('specialties', selectedSpecialty.color)}>
            <div className='centered main-content'>
                <div className='section-title'>
                    <h1>Especialidades</h1>
                </div>
                <div className='specialties-wrapper'>
                    {renderSpecialties()}
                </div>
                <div className='description-wrapper'>
                    <div className='title'>
                        <h1>{selectedSpecialty.title}</h1>
                    </div>
                    <div className='image'>
                        <RetinaImage src={[require('../assets/specialties/' + selectedSpecialty.icon + '-color.png'), require('../assets/specialties/' + selectedSpecialty.icon + '-color@2x.png')]} height='92' />
                    </div>
                    <div className='description'>
                        <p>{selectedSpecialty.description}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Specialty (props) {
    function handleClick () {
        props.onClick(props.index);
    }

    return (
        <div className='specialty-wrapper'>
            <div className={classNames('specialty', {selected: props.selected})} onClick={handleClick}>
                <RetinaImage src={[require('../assets/specialties/' + props.specialty.icon + '-black.png'), require('../assets/specialties/' + props.specialty.icon + '-black@2x.png')]} height='92' />
                <span className='title'>{props.specialty.title}</span>
            </div>
        </div>
    );
}

Specialty.propTypes = {
    specialty: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    selected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
}
