export const SUBMIT_CONTACT_FORM_START = 'SUBMIT_CONTACT_FORM_START';
export function submitContactFormStart () {
    return {
        type: SUBMIT_CONTACT_FORM_START
    };
}

export const SUBMIT_CONTACT_FORM_SUCCESS = 'SUBMIT_CONTACT_FORM_SUCCESS';
export function submitContactFormSuccess () {
    return {
        type: SUBMIT_CONTACT_FORM_SUCCESS
    };
}

export const SUBMIT_CONTACT_FORM_ERROR = 'SUBMIT_CONTACT_FORM_ERROR';
export function submitContactFormError (error) {
    return {
        type: SUBMIT_CONTACT_FORM_ERROR,
        error: error
    };
}

export const RESET = 'RESET';
export function resetReducer () {
    return {
        type: RESET
    };
}
