import '../styles/brands.scss';

import React from 'react';
import PropTypes from 'prop-types';

import RetinaImage from 'react-retina-image';

export default function Brands (props) {
    return (
        <div className='brands' id='marcas'>
            <div className='hero'>
                <div className='image' style={{backgroundImage: `url(${require('../assets/hero-4.jpg')})`}} />
                <div className='compatible-with-outer-wrapper'>
                    <div className='compatible-with-inner-wrapper'>
                        <CompatibleWith src='amazon-alexa' />
                        <CompatibleWith src='apple-homekit' />
                        <CompatibleWith src='google-assistant' />
                    </div>
                </div>
            </div>
            <div className='centered'>
                <div className='section-title'>
                    <h1>Marcas</h1>
                </div>
                <div className='reduced-wrapper'>
                    <p className='main-text'>Trabajamos únicamente con las mejores marcas y aquellos productos que son fáciles de usar. No instalamos equipo complicado, que requiere de mucha programación o que es difícil de conseguir. </p>
                    <BrandImage src='savant' />
                    <BrandImage src='lutron' />
                    <BrandImage src='sonos' />
                    <BrandImage src='nest' />
                    <BrandImage src='ring' />
                    <BrandImage src='august' />
                    <BrandImage src='ubiquiti' />
                    <BrandImage src='honeywell' />
                    <span className='icon-decor-wave' />
                    <p>Integramos lo que hacemos con tus dispositivos móviles y con cualquier servicio de voz reconocido.</p>
                </div>
            </div>
        </div>
    );
}

function BrandImage (props) {
    return (
        <div className='brand'>
            <RetinaImage src={[require('../assets/brands/' + props.src + '.png'), require('../assets/brands/' + props.src + '@2x.png')]} height='44' />
        </div>
    );
}

BrandImage.propTypes = {
    src: PropTypes.string.isRequired
}

function CompatibleWith (props) {
    return (
        <div className='compatible-with'>
            <RetinaImage src={[require('../assets/brands/' + props.src + '.jpg'), require('../assets/brands/' + props.src + '@2x.jpg')]} height='60' />
        </div>
    );
}

CompatibleWith.propTypes = {
    src: PropTypes.string.isRequired
}
