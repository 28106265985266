import '../styles/not-found.scss';

import React from 'react';
import Helmet from '@patio23/shared-components/lib/helmet';

export default function NotFound (props) {
    return (
        <div className='not-found'>
            <Helmet>
                <title>404 - Página no encontrada</title>
                <meta property='og:description' content='404 - Página no encontrada.' />
            </Helmet>
            <div className='wrapper'>
                <div className='text'>
                    <h1>404</h1>
                    <span className='icon-decor-wave' />
                    <p>Página no encontrada</p>
                </div>
            </div>
        </div>
    );
}
